import React, { useEffect, useState } from "react";

// 다른 파일에서 import
import { Product, Case, Option, Content } from "../models/products"; // 타입이 정의된 파일 경로
import YouTube from "react-youtube";
import VideoList from "./ProductList";
// VideoList 인터페이스
interface VideoList {
  content_id: number;
  contents_key: string;
  productList: Product[];
}

// 위에서 정의한 Content, Product, Option, Case, VideoList 타입들을 임포트
interface Video {
  id: number;
  title: string;
  videoId: string; // 예시로 추가된 필드
  contents_source: string; // 해당 필드가 콘텐츠 소스를 포함한다고 가정
}
// priceFormatter.ts
export const formatPrice = (price: number) => {
  return new Intl.NumberFormat("ko-KR", {
    style: "currency",
    currency: "KRW",
  }).format(price);
};
const VideoListComponent: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [videoList, setVideoList] = useState<VideoList[]>([]); // VideoList 객체를 저장할 상태
  const fetchVideos = async () => {
    try {
      // //const videoUrl =
      // ("http://localhost:3000/api/v1/re-contents-list?page=1&limit=100&sort=id,DESC"); // 첫 번째 API URL
      const videoUrl =
        "https://yuri341.duckdns.org/api/v1/re-contents-list?page=1&limit=100&sort=id,DESC"; // 첫 번째 API URL

      const videoResponse = await fetch(videoUrl);
      const responseData = await videoResponse.json();

      // 실제 데이터는 responseData.data 안에 있을 수 있음
      const data: Content[] = responseData.data;

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // 로딩 완료
    }
  };

  const fetchProduct = async () => {
    const data = await fetchVideos(); // fetchVideos 호출

    if (data) {
      // 첫 번째 API 응답에서 content_source를 추출
      const contentSources = data
        .map((content) => content.contents_source)
        .join(","); // 콤마로 결합하여 ids 생성

      // 두 번째 API 요청: Product 데이터를 가져오는 URL
      //const productUrl = `http://localhost:3000/api/v1/products?id=${contentSources}`;
      const productUrl = `https://yuri341.duckdns.org/api/v1/products?id=${contentSources}&limit=200`;
      const productResponse = await fetch(productUrl);
      const json = await productResponse.json();
      const productData: Product[] = json.data;

      console.log({ productUrl });

      // 각 content_id와 ProductList를 매핑하여 VideoList 객체 생성
      const videoListData: VideoList[] = data.map((content) => ({
        content_id: content.id,
        contents_key: content.contents_key,
        productList: productData.filter((product) => {
          const splited = content.contents_source.split(",");
          // content.contents_source가 여러 개의 ID를 콤마로 구분된 문자열이라고 가정
          return content.contents_source
            .split(",")
            .includes(String(product.id)); // product.id가 contents_source에 포함되어 있는지 확인
        }),
      }));

      console.log(videoListData);
      setVideoList(videoListData);
    }
  };
  useEffect(() => {
    fetchProduct();
  }, []); // 컴포넌트가 마운트될 때 한 번만 실행

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="containerVideo">
      <ul>
        {videoList.map((videoData) => (
          <li key={videoData.content_id}>
            <YouTube
              videoId={videoData.contents_key}
              opts={{
                width: "340",
                height: "315",
                playerVars: {
                  autoplay: 0, //자동재생 O
                  rel: 0, //관련 동영상 표시하지 않음 (근데 별로 쓸모 없는듯..)
                  modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                },
              }}
              //이벤트 리스너
              onEnd={(e) => {
                e.target.stopVideo(0);
              }}
            />
            <div className="titleLabel">
              쇼츠에 등장하는 상품을 아래에 모아보았어요!.
            </div>
            <div className="productWrap">
              <VideoList videoData={videoData} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VideoListComponent;
