import { LineChart } from "@mui/x-charts";
import { Items, ProductList, Props } from "../type";
import numberWithCommas from "../utils";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import SubMain from "../subs";

import { Product, Case, Option, Content } from "../models/products"; // 타입이 정의된 파일 경로
const domain = "https://restocking.xyz";
const subMainLink = "/SubMain";
function paginate(
  array: ProductList[],
  page_size: number,
  page_number: number
) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}
interface ListItem {
  id: number;
  name: string;
  category_id: number;
  code: number | null; // code가 null일 수 있음
}

export type ListCategoryProps = {
  list: ListItem[];
  onOpenModal?: () => void;
  category_id?: number;
  count: number;
};

export default function ListCategory({
  list,
  category_id: cid,
  count,
}: ListCategoryProps): JSX.Element {
  console.log("-->", list, cid);
  const showData: ProductList[] = [];
  //const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  }

  const [productList, setProductList] = useState<Product[]>([]); // VideoList 객체를 저장할 상태
  const fetchProduct = async () => {
    try {
      const codesString = list
        .map((item) => item.code?.toString()) // `code` 값을 문자열로 변환
        .filter((code) => code !== undefined) // `undefined` 값 제외
        .join(","); // 콤마로 구분된 문자열로 결합

      console.log(codesString);
      const productUrl = `https://yuri341.duckdns.org/api/v1/products?id=${codesString}`;
      const productResponse = await fetch(productUrl);
      const json = await productResponse.json();
      const productData: Product[] = json.data;

      setProductList(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      //setLoading(false); // 로딩 완료
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []); // 컴포넌트가 마운트될 때 한 번만 실행

  const paginCnt = 4;
  const ulCnt = Math.floor(showData.length / paginCnt);
  const arr = new Array(ulCnt).fill(0);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modalStyle"
      >
        <SubMain category_id={cid} closeModal={closeModal} />
      </Modal>
      <PagedUl
        list={productList}
        onOpenModal={openModal}
        category_id={cid}
        count={count}
      />
    </>
  );
}

export type ListCategoryProductProps = {
  list: Product[];
  onOpenModal?: () => void;
  category_id?: number;
  count: number;
};

const PagedUl = ({
  list,
  category_id,
  count,
  onOpenModal,
}: ListCategoryProductProps) => {
  return (
    <>
      <div>
        <ul className="recentListUl">
          {list.map(({ name, options, id }, index) => {
            if (count && index > count) {
              return <></>;
            }

            const { image, cases } = options[0];
            const { price: currentPrice, link: outlink } = cases[0];

            const imageLink = image
              ? image.replace("s3.ap-northeast-2.amazonaws.com/www.", "")
              : "";
            const detailLink = `/product/${id}`;
            return (
              <li key={id} className="productRecentListLi">
                <a href={detailLink} className="boxHref">
                  <div className="imageWrapLi">
                    <img
                      src={imageLink}
                      width="150"
                      height="150"
                      className="imageThum"
                    />
                  </div>

                  <div className="desc">
                    <ul>
                      <li className="descLi">{name}</li>
                      <li className="liPrice">
                        {/* <img
                      src="/img/sale_ic.png"
                      width="30"
                      height="30"
                      className="imageThum"
                    /> */}
                        {numberWithCommas(currentPrice)}
                        {"원"}
                      </li>
                      <li className="liDesc1">가격 변동이 있어요!</li>
                      <li className="liDesc2">
                        자세한 할인 내역을 클릭해서 보셔요.
                      </li>
                      {/* <li>
                      <a
                        href={`https://search.naver.com/search.naver?query=${name}+코스트코`}
                        target="_blank"
                        className="buttonToCoupangWrapTag"
                      >
                        <div className="buttonToDetail">자세히 보기</div>
                      </a>
                    </li> */}
                      <div>
                        {outlink == null ? (
                          <></>
                        ) : (
                          <div className="buttonToCoupangWrap">
                            <a
                              //href={`https://search.naver.com/search.naver?query=${name}+코스트코`}
                              href={outlink}
                              target="_blank"
                              className="buttonToCoupangWrapTag"
                            >
                              {/* <div className="buttonToCoupang">
                                쿠팡 유사 상품
                              </div> */}
                            </a>
                          </div>
                        )}
                      </div>
                    </ul>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      {/* {count > 0 ? (
        <div onClick={onOpenModal} className="moreWrap">
          {"더보기 > "}
        </div>
      ) : (
        <></>
      )} */}
    </>
  );
};
