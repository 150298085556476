import React from "react";

interface ProductOption {
  image: string;
  price: number;
}

interface Product {
  id: number;
  name: string;
  options: ProductOption[];
}

interface VideoData {
  productList: Product[];
}

// Price 포맷팅 함수
const formatPrice = (price: number): string => {
  return price.toLocaleString("ko-KR", { style: "currency", currency: "KRW" });
};

// ProductItem 컴포넌트
const ProductItem: React.FC<{ product: Product }> = ({ product }) => {
  const productImage = product.options[0].image.replaceAll(
    "s3.ap-northeast-2.amazonaws.com/www.",
    ""
  );
  const formattedPrice = formatPrice(product.options[0].price).replaceAll(
    "₩",
    ""
  );

  return (
    <li key={product.id} className="product-item">
      <a href={`/product/${product.id}`}>
        <ul className="video-item-list">
          <li>
            <img
              src={productImage}
              className="imgVideoProd"
              alt={product.name}
            />
            <div>{product.name}</div>
            <h4>{formattedPrice} 원</h4>
          </li>
        </ul>
      </a>
    </li>
  );
};

// VideoList 컴포넌트
interface VideoListProps {
  videoData: VideoData;
}

const VideoList: React.FC<VideoListProps> = ({ videoData }) => {
  return (
    <ul className="video-list">
      {videoData.productList.map((product) => (
        <ProductItem key={product.id} product={product} />
      ))}
    </ul>
  );
};

export default VideoList;
