import React from "react";

// 다른 파일에서 import
import { Product, Case, Option, Content } from "../models/products";
// Price 포맷팅 함수
const formatPrice = (price: number): string => {
  return price
    .toLocaleString("ko-KR", { style: "currency", currency: "KRW" })
    .replace("₩", "");
};
const LabelBox: React.FC<Product> = ({ options, name }) => {
  const firstCase = options[0].cases[0];
  const source_id = firstCase ? firstCase.sourceId : "";
  const brand = name.split(" ")[0];
  const firstIndex = name.indexOf(" ");
  const onlyName = name.substring(
    firstIndex == -1 ? 0 : firstIndex,
    name.length
  );

  const sale_price = firstCase ? firstCase.saleAmount : 0;
  const price = options[0].price;
  return (
    <div className="label-box">
      <div className="step1">
        <div className="wrapBar">
          <div className="step2">{source_id}</div>
          <div className="barcode">
            <img src="/img/barcode.png" alt="코스트코 반띵" />
          </div>
        </div>
        <div className="step2">{brand}</div>
        <div className="step2">{onlyName}</div>
        <div className="wrapBarSale">
          {sale_price > 0 ? (
            <div className="step6">할인행사 (~ 2025/03/23)</div>
          ) : null}

          <div>
            {sale_price > 0 ? (
              <div className="step5">- {formatPrice(sale_price)}원</div>
            ) : null}
            <div className="step4">{formatPrice(price)}원</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabelBox;
