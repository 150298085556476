import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// 다른 파일에서 import
import { Product, Case, Option, Content } from "../models/products"; // 타입이 정의된 파일 경로
import BackButton from "../components/BackButton";
import { formatPrice } from "../components/VideoList";
import ProductItem from "../components/ProductItem";
import LabelBox from "../components/LabelBox";
interface RelationProduct {
  createdAt: string;
  updatedAt: string;
  isDelete: boolean;
  target_id: number;
  product_ids: string;
}

const PostDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [products, setProducts] = useState<Product[]>();
  const [costcoProducts, setCostcoProducts] = useState<Product[]>();
  const [coupangProducts, setCoupangProducts] = useState<Product[]>();

  const mergeProducts = (
    prevProducts: Product[] | undefined, // prevProducts는 Product[] 또는 null일 수 있음
    newProducts: Product[], // newProducts는 항상 Product[]이어야 함
    provider: string // provider는 string 타입
  ): Product[] => {
    return [
      ...(prevProducts || []), // prevProducts가 없으면 빈 배열 사용
      ...newProducts.filter(
        (newProduct) =>
          !prevProducts?.some(
            (existingProduct) =>
              existingProduct.options[0].cases[0].sourceId ===
              newProduct.options[0].cases[0].sourceId
          )
      ),
    ];
  };

  const fetchProductRelative = async () => {
    const productUrl = `https://yuri341.duckdns.org/api/v1/relation-product`;
    const productResponse = await fetch(productUrl);
    const json = await productResponse.json();
    const relationData: RelationProduct[] = json;

    const relationDataByTarget = relationData.filter(
      (ele) => ele.target_id === Number(id)
    )[0];

    if (!relationDataByTarget) return null;

    const ids = relationDataByTarget.product_ids;
    var url = `https://yuri341.duckdns.org/api/v1/products?id=${ids}`;

    const response = await fetch(url);
    const pJson = await response.json();
    const productData: Product[] = pJson.data;

    const filteredCostco = productData.filter(
      (ele) => ele.options[0].cases[0].provider === "COSTCO"
    );
    const filteredCoupang = productData.filter(
      (ele) => ele.options[0].cases[0].provider === "COUPANG"
    );

    // Costco 제품 병합
    setCostcoProducts((prevCostcoProducts) => {
      return mergeProducts(prevCostcoProducts, filteredCostco, "COSTCO");
    });

    // Coupang 제품 병합
    if (filteredCoupang.length > 0) {
      setCoupangProducts((prevCproducts) => {
        return mergeProducts(prevCproducts, filteredCoupang, "COUPANG");
      });
    }
  };

  const fetchProduct = async () => {
    const productUrl1 = `https://yuri341.duckdns.org/api/v1/products?id=${id}`;
    const res = await fetch(productUrl1);
    const stepJson1 = await res.json();
    const productData1: Product[] = stepJson1.data;
    setProducts(productData1);

    const splited = productData1[0].name.split(" ");
    const keyword =
      splited.length > 1
        ? productData1[0].name.split(" ")[0] +
          " " +
          productData1[0].name.split(" ")[1]
        : productData1[0].name.split(" ")[0];

    const productUrl = `https://yuri341.duckdns.org/api/v1/products?page=1&limit=100&name=${keyword}`;
    const productResponse = await fetch(productUrl);
    const json = await productResponse.json();
    const productData: Product[] = json.data;

    const filteredCostco = productData.filter(
      (ele) => ele.options[0].cases[0].provider === "COSTCO"
    );
    const filteredCoupang = productData.filter(
      (ele) => ele.options[0].cases[0].provider === "COUPANG"
    );

    // Costco 제품 병합
    setCostcoProducts((prevCostcoProducts) => {
      return mergeProducts(prevCostcoProducts, filteredCostco, "COSTCO");
    });

    // Coupang 제품 병합
    setCoupangProducts((prevCpProducts) => {
      return mergeProducts(prevCpProducts, filteredCoupang, "COUPANG");
    });
  };

  useEffect(() => {
    fetchProduct();
    fetchProductRelative();
  }, []); // 컴포넌트가 마운트될 때 한 번만 실행

  if (!products) {
    return null;
  }

  const product = products[0];
  const option = product.options[0];
  const caseObj = option.cases[0];

  const imageLink = option.image
    ? option.image.replace("s3.ap-northeast-2.amazonaws.com/www.", "")
    : "";
  console.log({ coupangProducts });
  return (
    <div className="product-item">
      <BackButton />
      <div className="product-content">
        {/* Loop through options to display the first option's image and price */}
        <div key={option.id} className="product-option">
          <div className="product-image">
            {/* Main product image */}
            <img src={imageLink} alt={option.label} className="main-image" />
            {/* Overlay image */}
            <LabelBox {...product} />
          </div>
        </div>
      </div>
      <div className="relative-container">
        <div className="coup-container">
          <img
            src="/img/COSTCO_img_back.png"
            alt="반띵"
            width="60"
            height="20"
          />
          코스트코 상품
        </div>
        <div className="product-list">
          {costcoProducts
            ? costcoProducts.map((product) => (
                <ProductItem key={product.id} product={product} />
              ))
            : null}
        </div>
        {coupangProducts && coupangProducts.length > 0 ? (
          <div>
            <div className="coup-container">
              <img
                src="/img/COUPANG_img_back.png"
                alt="반띵"
                width="60"
                height="20"
              />{" "}
              쿠팡 상품
            </div>
            <div className="product-list">
              {coupangProducts.map((product) => (
                <ProductItem key={product.id} product={product} />
              ))}
            </div>
            <div className="titleCoupangPartner">
              "이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의
              수수료를 제공받습니다."
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PostDetailPage;
