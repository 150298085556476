import React, { useEffect, useState } from "react";
import axios from "axios";

// Define a type for the user profile response
interface UserProfileResponse {
  name: string;
  email: string;
  profile_image: string;
}

const UserProfile = () => {
  const [userInfo, setUserInfo] = useState<UserProfileResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    if (token) {
      axios
        .get("https://yuri341.duckdns.org/api/v1/auth/getUserInfo", {
          params: {
            access_token: token,
          },
        })
        .then((response) => {
          const userInfo = response.data.response;
          console.log({ userInfo });
          setUserInfo(response.data.response); // Adjust according to API structure
        })
        .catch((error) => {
          setError("사용자 정보 가져오기 실패");
          console.error("사용자 정보 가져오기 실패", error);
          localStorage.removeItem("access_token");
        });
    } else {
      //window.location.href = "/login";
    }
  }, []);

  const handleLogout = () => {
    // 로그아웃 시 sessionStorage에서 액세스 토큰 삭제
    localStorage.removeItem("access_token");
    window.location.href = "/";
  };
  if (error) {
    return <div>{error}</div>;
  }

  if (!userInfo) {
    return <div>사용자 정보를 불러오는 중...</div>;
  }

  return (
    <div className="mypage-container">
      <h1>사용자 프로필</h1>
      <p>이름: {userInfo.name}</p>
      <p>이메일: {userInfo.email}</p>
      {/* <img src={userInfo.profile_image} alt="Profile" /> */}
      <div className="auth">
        <button className="download-button" onClick={handleLogout}>
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
