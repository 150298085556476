import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
const Header: React.FC = () => {
  const [isSearchVisible, setSearchVisible] = useState(true); // 검색 박스 표시/숨김 상태 관리
  const [accessToken, setAccessToken] = useState(""); // 검색 박스 표시/숨김 상태 관리
  // useNavigate 훅을 사용하여 페이지 이동 기능 구현
  const navigate = useNavigate();

  const handleDownloadsClick = () => {
    // 클릭 시 "/download" 페이지로 이동
    navigate("/downloads");
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    setAccessToken(token ?? "");
  }, []);

  return (
    <header className="headerContainer">
      <div className="header">
        {/* 반띵 로고 이미지 */}
        <Link to="/">
          {" "}
          <img src="/img/even_logo.png" alt="반띵" width="80" height="80" />
        </Link>
        {/* 아이콘 추가 (예: Home 아이콘) */}
        {/* <i className="fas fa-home logo-icon"></i>  
        <nav className="nav-left">
          <ul>
            <li>
              <Link to="/">
                코스트코 인기 쇼츠<i className="fas fa-star"></i>
              
              </Link>
            </li>
          </ul>
        </nav>*/}
      </div>

      <div className="header-right">
        <div className="auth">
          <Link to="/login">{accessToken ? "마이페이지" : "로그인"}</Link>
        </div>
        <div className="auth">
          <button className="download-button" onClick={handleDownloadsClick}>
            앱 다운로드
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
