import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EvenEvent } from "./HomePage";
import { Product } from "../models/products";
import { Helmet } from "react-helmet";
interface EventProduct {
  id: number;
  event_id: number;
  display: boolean;
  product_id: number;
  product: Product; // 빈 객체일 수도 있지만, 실제 데이터 구조를 반영할 수 있음
  createdAt: string; // ISO 날짜 형식
  updatedAt: string; // ISO 날짜 형식
  isDelete: boolean;
}

// priceFormatter.ts
export const formatPrice = (price: number) => {
  return new Intl.NumberFormat("ko-KR", {
    style: "currency",
    currency: "KRW",
  }).format(price);
};
// ProductItem 컴포넌트
const ProductItem: React.FC<{ product: Product }> = ({ product }) => {
  const productImage = product.options[0].image.replaceAll(
    "s3.ap-northeast-2.amazonaws.com/www.",
    ""
  );
  const formattedPrice = formatPrice(product.options[0].price).replaceAll(
    "₩",
    ""
  );

  return (
    <li key={product.id} className="product-item">
      <a href={`/product/${product.id}`}>
        <ul className="video-item-list">
          <li>
            <img
              src={productImage}
              className="imgVideoProd"
              alt={product.name}
            />
            <div className="productName">{product.name}</div>
            <h4>{formattedPrice} 원</h4>
          </li>
        </ul>
      </a>
    </li>
  );
};

const EventPage: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { id } = useParams<{ id: string }>();

  const [eventData, setEventData] = useState<EvenEvent>();
  const [dataList, setDataList] = useState<EventProduct[]>([]);

  const fetchEvent = async () => {
    //
    try {
      const eventUrl = `https://yuri341.duckdns.org/api/v1/re-event?s={%22id%22:${id}}`; // 첫 번째 API URL
      //const eventUrl = "https://yuri341.duckdns.org/api/v1/re-event"; // 첫 번째 API URL

      const videoResponse = await fetch(eventUrl);
      const responseData = await videoResponse.json();

      // 실제 데이터는 responseData.data 안에 있을 수 있음

      if (responseData.length > 0) {
        setEventData(responseData[0]);
      }

      const dataUrl = `https://yuri341.duckdns.org/api/v1/re-event-product?s={%22event_id%22:${id}}&sort=_p.createdAt,DESC`;

      const dataResponse = await fetch(dataUrl);
      const res = await dataResponse.json();

      console.log(res);
      if (res.length > 0) {
        setDataList(
          res.filter((ele: EventProduct) => ele.product.options[0].image != "")
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  useEffect(() => {
    // 화면 크기 기준으로 모바일 여부 판단 (기본적으로 768px 이하를 모바일로 간주)
    const checkMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // 처음 로드 시 체크
    checkMobile();

    fetchEvent();

    // 리사이즈 이벤트에 따라 체크
    window.addEventListener("resize", checkMobile);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  if (!eventData) {
    return null;
  }

  console.log(eventData);
  return (
    <div className="home-page">
      <Helmet>
        <title>반띵 - 할인상품만 모아봤어요.</title>
        <meta name="반띵" content="코스트코 할인상품만 모아봤어요." />
        <meta
          name="keywords"
          content="반띵,코스트코추천,코스트코핫템,코스트코추천템,트레이더스추천,트레이더스,코스트코,추천,코스트코 식기세척기,초콜릿,대량구매,코스트코반띵"
        />
      </Helmet>
      <div className="event-page-section">
        <img
          src={
            isMobile
              ? eventData.banner_image.replace("_1200.png", "_380.png")
              : eventData.banner_image
          }
          alt="반띵"
          width="100%"
          height="100%"
        />
        <div className="productWrap">
          <ul className="video-list">
            {dataList.map(({ product }) => (
              <ProductItem key={product.id} product={product} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventPage;
