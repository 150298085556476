import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const BackButton: React.FC = () => {
  const navigate = useNavigate(); // Hook to navigate between pages

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <button onClick={handleBackClick} className="back-button">
      <i className="fas fa-arrow-left"></i> {/* Font Awesome left arrow icon */}
      <span>뒤로 가기</span>
    </button>
  );
};

export default BackButton;
