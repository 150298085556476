import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"; // BrowserRouter와 Routes로 변경
import HomePage from "./pages/HomePage";
import PostDetailPage from "./pages/PostDetailPage";

import "./App.css";
import Sidebar from "./components/SideBar";
import Detail from "./detail";
import Login from "./login";

import { Link } from "react-router-dom";
import Downloads from "./pages/Downloads";
import Callback from "./login/callback";
import UserProfile from "./pages/Mypage";
import Header from "./components/Header";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import EventPage from "./pages/EventPage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCpC2hxzlgM58LzAjmAkW31Uxhkhq8fEcA",
  authDomain: "re-stock-cb7ad.firebaseapp.com",
  databaseURL:
    "https://re-stock-cb7ad-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "re-stock-cb7ad",
  storageBucket: "re-stock-cb7ad.firebasestorage.app",
  messagingSenderId: "717718012460",
  appId: "1:717718012460:web:e30077a57c17bd7ff38789",
  measurementId: "G-LD6T67X6B7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const App: React.FC = () => {
  return (
    <BrowserRouter>
      {" "}
      {/* BrowserRouter로 감싸기 */}
      <div className="App">
        <Header />
        <div className="main-container">
          <Routes>
            {" "}
            {/* Routes로 변경 */}
            {/* 기존 홈 페이지 */}
            <Route path="/" element={<HomePage />} />
            {/* 게시물 상세 페이지 */}
            <Route path="/product/:id" element={<PostDetailPage />} />
            <Route path="/event/:id" element={<EventPage />} />
            {/* 게시물 상세 페이지 */}
            <Route path="/downloads" element={<Downloads />} />
            {/* 추가적인 페이지들 */}
            <Route path="/detail/:vendorId/:id" element={<Detail />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login/callback" element={<Callback />} />
            <Route path="/mypage" element={<UserProfile />} />
          </Routes>
        </div>

        {/* Footer 추가 */}
        <footer className="footer">
          <div className="footer-content">
            <p>&copy; 2025 Kiyoing. All rights reserved.</p>
            <div className="footer-links">
              <Link to="/contact">zestime8@gmail.com</Link>
            </div>
          </div>
        </footer>
      </div>
    </BrowserRouter>
  );
};

export default App;
