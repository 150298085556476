import React, { useCallback, useEffect, useState } from "react";
import useDeviceDetection from "../hooks/useDeviceDetection";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { Swiper as SwiperCore } from "swiper";
import PostCard from "../components/PostCard";
import { Helmet } from "react-helmet";

const Downloads: React.FC = () => {
  const device = useDeviceDetection();
  const [value, setValue] = React.useState(0);
  //alert(device);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {};

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // 화면 크기 기준으로 모바일 여부 판단 (기본적으로 768px 이하를 모바일로 간주)
    const checkMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // 처음 로드 시 체크
    checkMobile();

    // 리사이즈 이벤트에 따라 체크
    window.addEventListener("resize", checkMobile);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return (
    <div className="home-page">
      <Helmet>
        <title>반띵 - 메인페이지</title>
        <meta
          name="반띵"
          content="코스트코에서 너무 대량 구매하셨나요? 앱을 다운받아서 반띵하세요!."
        />
        <meta
          name="keywords"
          content="반띵,코스트코추천,코스트코핫템,코스트코추천템,트레이더스추천,트레이더스,코스트코,추천,코스트코 식기세척기,초콜릿,대량구매,코스트코반띵"
        />
      </Helmet>
      {/* <div className="swiperWrap">
        <div className={isMobile ? "swiper-container" : "swiper-container-web"}>
          <Swiper
            spaceBetween={50}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper: SwiperCore) => console.log(swiper)}
            loop={true} // 슬라이드가 끝나면 처음으로 돌아가도록 설정
            navigation={{
              nextEl: ".swiper-button-next", // '다음' 버튼을 정의
              prevEl: ".swiper-button-prev", // '이전' 버튼을 정의
            }}
          >
            <div className="swiper-button-next">
              <i className="fas fa-chevron-right"></i>
            </div>
            <div className="swiper-button-prev">
              <i className="fas fa-chevron-left"></i>
            </div>

            <SwiperSlide></SwiperSlide>
            <SwiperSlide></SwiperSlide>
          </Swiper>
        </div>
      </div> */}
      <div className="img-desc">
        <div className="TextSwiper">코스트코에서 너무 대량 구매하셨나요?</div>
        <img src="/img/pp1.jpeg" alt="Slide 1" className="imgExcluded" />
        <div className="TextSwiper">반띵 앱으로 나누어 보세요!</div>
        <img src="/img/pp4.png" alt="Slide 1" className="imgExcluded" />
      </div>
      <div className="InstallWrap">
        <div>
          <button
            className="InstallButton"
            onClick={() =>
              window.open(
                "https://apps.apple.com/kr/app/%EB%B0%98%EB%9D%B5/id6740470773",
                "_blank"
              )
            }
          >
            <img
              src="/img/apple-icon.png"
              alt="Install Icon"
              className="icon-ioc"
            />

            <span className="InstallButtonSpan">아이폰 앱 다운로드</span>
          </button>
          <button
            className="InstallButton"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=xyz.restocking.mobile&gl=KR",
                "_blank"
              )
            }
          >
            <img
              src="./img/android-icon.png"
              alt="Install Icon"
              className="icon"
            />
            <span>안드로이드 앱 다운로드</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Downloads;
