import React, { useEffect } from "react";
import axios from "axios";

// 네이버 로그인에서 받아온 응답 타입 정의
interface NaverUserInfo {
  response: {
    id: string;
    email: string;
    nickname: string;
    profile_image: string;
  };
}

const Callback: React.FC = () => {
  const NAVER_CLIENT_ID = "cKPtjHNcHWBj7z9j0Sid"; // 환경 변수에서 클라이언트 아이디 읽기
  const REDIRECT_URI = "https://restocking.xyz/login/callback"; // Callback URL

  const CLIENT_SECRET = "Hfd5YSG84O"; // 환경 변수에서 클라이언트 시크릿 읽기
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code"); // 네이버 로그인에서 받은 code
    const state = queryParams.get("state");

    if (code && state) {
      getAccessToken(code, state); // 액세스 토큰 요청
    }
  }, []);

  const getAccessToken = async (code: string, state: string) => {
    try {
      const callbackUrl = `https://yuri341.duckdns.org/api/v1/auth/callback`;
      const response = await axios.get(callbackUrl, {
        params: {
          client_id: NAVER_CLIENT_ID,
          client_secret: CLIENT_SECRET,
          code: code,
          state: state,
          redirect_uri: REDIRECT_URI,
          grant_type: "authorization_code",
        },
      });
      console.log({ response });
      const access_token = response.data;
      localStorage.setItem("access_token", access_token);
      window.location.href = "/mypage";
    } catch (error) {
      console.log(error);
      console.error("로그인 처리 중 오류가 발생했습니다.", error);

      localStorage.removeItem("access_token");
    }
  };

  return (
    <div className="loginContainer">
      <div className="loginDesc"></div>
    </div>
  );
};

export default Callback;

// const NaverLogin = () => {
//   const clientId = "cKPtjHNcHWBj7z9j0Sid";
//   const callbackUrl = "http://localhost:3001/login/callback";

//   const { naver }: any = window;

//   useEffect(() => {
//     const scriptNaver1 = document.createElement("script");
//     scriptNaver1.src =
//       "https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js";
//     scriptNaver1.type = "text/javascript";
//     scriptNaver1.async = true;
//     document.body.appendChild(scriptNaver1);

//     const scriptNaver2 = document.createElement("script");
//     scriptNaver2.src = "http://code.jquery.com/jquery-1.11.3.min.js";

//     scriptNaver2.type = "text/javascript";
//     scriptNaver2.async = true;
//     document.body.appendChild(scriptNaver2);

//     console.log("scriptNaver1 added");
//     const initializeNaverLogin = () => {
//       if (naver === undefined) {
//         alert(
//           "네이버 아이디로 로그인 모듈을 초기화하는 중입니다. 잠시만 기다려주세요."
//         );
//         return;
//       }
//       const naverLogin = new naver.LoginWithNaverId({
//         clientId,
//         callbackUrl,
//         isPopup: true,
//         loginButton: { color: "green", type: 3, height: "48" },
//       });
//       naverLogin.init();
//     };

//     initializeNaverLogin();
//     return () => {
//       document.body.removeChild(scriptNaver1);
//       document.body.removeChild(scriptNaver2);
//     };
//   }, []);

//   //네이버 로그인 버튼 노출 영역
//   return <div id="naver_id_login"></div>;

//   //네이버 로그인 버튼 노출 영역
// };

//     <script type="text/javascript">
//     var naver_id_login = new naver_id_login("cKPtjHNcHWBj7z9j0Sid",
//     "Hfd5YSG84O"); var state = naver_id_login.getUniqState();
//     naver_id_login.setButton("white", 2,40);
//     naver_id_login.setDomain("localhost:3001");
//     naver_id_login.setState(state); naver_id_login.setPopup();
//     naver_id_login.init_naver_id_login();
//   </script>

/*
네이버 로그인 JavaScript 예제는 2개의 파일로 구성되어 있습니다. (naverlogin.html, callback.html)
1. APIExamNaverLogin.html
<!doctype html>
<html lang="ko">
<head>
  <meta charset="utf-8">
  <title>네이버 로그인</title>
  <script type="text/javascript" src="https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js" charset="utf-8"></script>
  <script type="text/javascript" src="http://code.jquery.com/jquery-1.11.3.min.js"></script>
</head>
<body>
  <!-- 네이버 로그인 버튼 노출 영역 -->
  <div id="naver_id_login"></div>
  <!-- //네이버 로그인 버튼 노출 영역 -->
  <script type="text/javascript">
  	var naver_id_login = new naver_id_login("YOUR_CLIENT_ID", "YOUR_CALLBACK_URL");
  	var state = naver_id_login.getUniqState();
  	naver_id_login.setButton("white", 2,40);
  	naver_id_login.setDomain("YOUR_SERVICE_URL");
  	naver_id_login.setState(state);
  	naver_id_login.setPopup();
  	naver_id_login.init_naver_id_login();
  </script>
</html>


2. callback.html
<!doctype html>
<html lang="ko">
<head>
<script type="text/javascript" src="https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js" charset="utf-8"></script>
<script type="text/javascript" src="http://code.jquery.com/jquery-1.11.3.min.js"></script>
</head>
<body>
<script type="text/javascript">
  var naver_id_login = new naver_id_login("YOUR_CLIENT_ID", "YOUR_CALLBACK_URL");
  // 접근 토큰 값 출력
  alert(naver_id_login.oauthParams.access_token);
  // 네이버 사용자 프로필 조회
  naver_id_login.get_naver_userprofile("naverSignInCallback()");
  // 네이버 사용자 프로필 조회 이후 프로필 정보를 처리할 callback function
  function naverSignInCallback() {
    alert(naver_id_login.getProfileData('email'));
    alert(naver_id_login.getProfileData('nickname'));
    alert(naver_id_login.getProfileData('age'));
  }
</script>
</body>
</html>



*/
