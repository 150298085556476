import React from "react";
import { Product } from "../models/products";
import { formatPrice } from "./VideoList";

type ProductItemProps = {
  product: Product;
};

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  if (product.options == null || product.options.length == 0) {
    return null;
  }
  const option = product.options[0];
  const caseObj = option.cases[0];

  const splited = product.name.split(" ");

  const keyword =
    splited.length > 1
      ? product.name.split(" ")[0] + " " + product.name.split(" ")[1]
      : product.name.split(" ")[0];
  const href =
    caseObj.provider === "COSTCO"
      ? "https://www.costco.co.kr/search?text=" + keyword
      : caseObj.link;

  const imageLink = option.image
    ? option.image.replace("s3.ap-northeast-2.amazonaws.com/www.", "")
    : "";
  console.log({ option });
  return (
    <a href={href} target="_blank">
      <div className="relative-product-item">
        <div className="relative-product-image">
          <img src={imageLink} alt={product.name} />
        </div>
        <div className="relative-product-details">
          <h3>{product.name}</h3>
          <h3>
            {caseObj.saleAmount
              ? `할인행사 (${caseObj.saleDate}) -` +
                formatPrice(caseObj.saleAmount).replace("₩", "") +
                "원"
              : ""}
          </h3>
          <p className="relative-product-price">
            {formatPrice(option.price).replace("₩", "")}원
          </p>
          <p></p>
        </div>
      </div>
    </a>
  );
};

export default ProductItem;
