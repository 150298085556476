import React, { useCallback, useEffect, useState } from "react";
import { KeyboardEvent } from "react";
import List from "../components/list";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import gproducts from "../jsons/products.json";
import products from "../jsons/categoryProducts.json";
import categories from "../jsons/categories.json";
import bestCoupang from "../jsons/best_coupang.json";
import ListCategory from "../components/listCategory";
import ListCoupang from "../components/listCoupang";
import Display from "../displays";
import BottomNavigator from "../components/bottomNavigator";
import NaverLogin from "../login/naverLogin";

import { getAnalytics } from "firebase/analytics";
import useDeviceDetection from "../hooks/useDeviceDetection";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
// Import Swiper styles
import "swiper/css";
import { Swiper as SwiperCore } from "swiper";
import PostCard from "../components/PostCard";
import VideoListComponent from "../components/VideoList";
import Downloads from "./Downloads";
import SearchBox from "../components/SearchBox";
interface Post {
  id: number;
  title: string;
  content: string;
}
export interface EvenEvent {
  id: number;
  content_id: number;
  name: string;
  banner_image: string;
  display: boolean;
  isDelete: boolean;
  createdAt: string; // ISO 8601 format
  updatedAt: string; // ISO 8601 format
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}
const HomePage: React.FC = () => {
  const sortedProduct = products.sort((a, b) => b.id - a.id);
  const [productLists, setProductLists] = useState(sortedProduct);
  const [value, setValue] = React.useState(0);
  const device = useDeviceDetection();

  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const analytics = getAnalytics();

  useEffect(() => {
    // 뒤로 가기 버튼 클릭 시 iframe을 숨기고, 다시 표시하도록 설정
    const handlePopState = () => {
      setIsIframeVisible(false); // 뒤로 가기 시 iframe을 숨김
      setTimeout(() => {
        setIsIframeVisible(true); // 잠시 후 iframe을 보이게 함
      }, 300); // 300ms 후에 iframe이 보이도록 설정 (페이지 전환 애니메이션 고려)
    };

    // 뒤로 가기 이벤트 리스너 등록
    window.addEventListener("popstate", handlePopState);

    // 클린업: 이벤트 리스너 해제
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  //alert(device);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [isMobile, setIsMobile] = useState(false);

  const [eventList, setEventList] = useState<EvenEvent[]>([]);

  const fetchEvent = async () => {
    //
    try {
      // //const videoUrl =
      // ("http://localhost:3000/api/v1/re-contents-list?page=1&limit=100&sort=id,DESC"); // 첫 번째 API URL
      const eventUrl =
        "https://yuri341.duckdns.org/api/v1/re-event?s={%22display%22:true}"; // 첫 번째 API URL
      //const eventUrl = "https://yuri341.duckdns.org/api/v1/re-event"; // 첫 번째 API URL

      const videoResponse = await fetch(eventUrl);
      const responseData = await videoResponse.json();

      // 실제 데이터는 responseData.data 안에 있을 수 있음
      const data: EvenEvent[] = responseData;

      return setEventList([...data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  useEffect(() => {
    // 화면 크기 기준으로 모바일 여부 판단 (기본적으로 768px 이하를 모바일로 간주)
    const checkMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // 처음 로드 시 체크
    checkMobile();

    fetchEvent();

    // 리사이즈 이벤트에 따라 체크
    window.addEventListener("resize", checkMobile);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  console.log({ eventList });
  return (
    <div className="home-page">
      <div className="home-page-section">
        <Swiper className="mySwiper">
          {eventList.map((eventData) => (
            <SwiperSlide>
              <a href={`/event/${eventData.id}`}>
                <img
                  src={
                    isMobile
                      ? eventData.banner_image.replace("_1200.png", "_380.png")
                      : eventData.banner_image
                  }
                  alt="반띵"
                  width="100%"
                  height="200px"
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* <div>
        <SearchBox />
      </div> */}
      <div className="home-page-section">
        <div className="titleLabel">
          코스트코 인기 유투브 쇼츠를 가져왔어요!.
        </div>
        <div>
          <VideoListComponent />
        </div>
      </div>

      <div className="home-page-section">
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="코스트코 상품" {...a11yProps(1)} />
            {/* <Tab
              label="쿠팡 베스트상품"
              {...a11yProps(0)}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
              }}
            /> */}
            {/* <Tab label="코스트코 인기상품" {...a11yProps(2)} /> */}
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <div className="ProductPart">
              {categories.map(({ category_id: cid, title }) => {
                const plist = productLists.filter(
                  ({ category_id }) => category_id === cid
                );

                return (
                  <div className="topProducts">
                    <div className="titleSet">
                      <img src="./img/check-mark.png" width="30" height="30" />
                      {title}
                    </div>
                    <div className="setWrap">
                      {/* {videoData.productList.map((product) => (
                        <ProductItem key={product.id} product={product} />
                      ))} */}

                      <ListCategory list={plist} category_id={cid} count={5} />
                    </div>
                  </div>
                );
              })}
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="topProducts">
              <div>
                <ListCoupang list={bestCoupang} />
              </div>
            </div>
          </CustomTabPanel>
        </div>
      </div>
      {/* <div className="WrapProduct">
        <iframe
          src="https://ads-partners.coupang.com/widgets.html?id=780875&template=carousel&trackingCode=AF7382069&subId=&width=680&height=140&tsource="
          width="100%"
          height="140"
          className={isIframeVisible ? "show" : ""}
          style={{ display: isIframeVisible ? "block" : "none" }} // isIframeVisible 상태에 따라 iframe 표시 여부 결정
        ></iframe>
      </div> */}
      {/* <div className="titleCoupangPartner">
        "이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를
        제공받습니다."
      </div> */}
    </div>
  );
};

export default HomePage;
