import { useEffect } from "react";
import NaverLogin from "./naverLogin";

export default function Login() {
  useEffect(() => {
    const token = localStorage.getItem("access_token");

    if (token) {
      window.location.href = "/mypage";
    }
  }, []);

  return (
    <>
      <div className="loginWrap">
        <div>반갑습니다!</div>
        <div>로그인을 하고 상품알림을 받으세요!</div>
        <div>
          <NaverLogin />
        </div>
      </div>
    </>
  );
}
